import React from 'react';
import './App.scss';

import MainBody from './banner/body_selectedVideo/MainBody';


function SelectedVideo({videolist}){

  
  return(
    <body id="bodyVideo">
        <MainBody videolist={videolist}/>
    </body>
  )
}

export default SelectedVideo