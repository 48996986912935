import React,{useEffect, useState, Component, useRef} from 'react';
import { VideoPlayer } from './body/VideoPlayer';
import './adminStyle.scss';
import youtubeAPI from './apis/youtube'
import {Routes,Route, useNavigate} from "react-router-dom";
import data from './apis/data.json'
import './body/style.scss'


 function Admin() {
  const navi = useNavigate()
  const [videos,setVideos]=useState([])
  let VideoList=[]
  

  const onSearch = async keyword => {
    
    /* 
    
   try{
    const response = await youtubeAPI.get("/search", {
      params: {
        q: "test", 
        maxResults:20,
        videoDuration:"short",
        type:"video"
      }
  
  
     
    });

    //setVideos(response.data.items)
   
    VideoList.push(response.data.items)
    //setVideos(VideoList[0])


      setVideos(response.data.items)
    
    

    
   
    
  } 
  
  catch(error){

    //navi('/error')


  }
  */
  setVideos(data.items)


 
    }


   useEffect(()=>{
   
    
      
   })

  
  return (

    <div className='AdminPanel'>

    <div className='BlockVideos'>
  
 {/*<Videos y_videos={videos}/>*/}
   
 <iframe style={{height: '893px;',top: '76px;'}} data-src="https://adcheck.annalect.de/SoD_2/?medRec=1&fooSupBan=1&bb=1" id="pagetosearch" class="" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" src="https://adcheck.annalect.de/SoD_2/?medRec=1&fooSupBan=1&bb=1"></iframe>
    </div>

    <div className='ControlPanel'>
      
      <div className='Controls'>
      <input type="text" placeholder='Thema(test)' id="themafield"/>
      <input type="text" placeholder='Anzahl(test)' id="countfield"/>
      </div>

      <div className='Controls'>
        <div id="gobtn" onClick={onSearch}>OK</div>
      </div>
    
    </div>
     
     
    </div>


  );
   

}



function Videos(props){
 const y_videos=props.y_videos
  

  return y_videos.map(({snippet,id},index)=>{
    <div className="VideoPlayer" key={index} >
     <div className="Circl">
      <img className='Circl' src={snippet.thumbnails.medium.url} alt=""/>
     </div>
     <div className="TitleContainer">
     <p className="VideoName">{snippet.title}</p>
     <p className="ViewandChannel">{snippet.channelTitle}</p>
     <p className="ViewandChannel">Aufrufe</p>
     </div>
   </div>
   
  })
} 


export default Admin;
 
