import React,{useEffect, useState, Component, useRef} from 'react';

import './style.scss'
import  '../apis/youtube'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ReplyIcon from '@mui/icons-material/Reply';

import './script.js'
import SkipNextIcon from '@mui/icons-material/SkipNext';

import Error from './pic/error2.svg'

import Loadi from './pic/loading.gif'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SettingsIcon from '@mui/icons-material/Settings';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import Crop32Icon from '@mui/icons-material/Crop32';
import {CircularProgress} from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';

import VolumeUp from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';



import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';

import { useNavigate,Link,useParams, useSearchParams } from 'react-router-dom';
import Dialog from '../body/Dialog';


import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DataAds from '../apis/ads.json'



 // var paramsString = document.location.search; 
 // var searchParams = new URLSearchParams(paramsString);

  var type="pre"
 // var skipp=searchParams.get("s")
  var current_time_interval
  var status_interval
   var playerX
   var VideoEnd
   var AdEnded=false
   let WerbungVideoAd
 var ids_count=sessionStorage.getItem('ids_count') || 0
  let countwdownInterval 
  let countwdownInterval2 
  let emergency_ids=[]
  let like=539
  var duration
  

  let noIds=[]
  var userAgent = window.navigator.userAgent;
  var time=0;
  var videoDuration=0

  let timeafterskipbtn=0
  let timeafterskipvid=0



  function VideoPlayer({videolist}){
   
    const [isplayed, setPlay]=React.useState(true)
    const [value, setValue] = React.useState(1);
    const [countdown, setCountdown] = React.useState(5);
    const [mobile, setMobile]=React.useState(false)
    const items=useSelector(state=>state.array)
    const dispatch=useDispatch()
    emergency_ids=[]

    const ad1=useRef(null)
    const ad2=useRef(null)

    const countDownDiv=useRef(null)
    const SkipBtnimg=useRef(null)

    const {adindex}=useParams()
    const [searchParams] = useSearchParams();
    const adindex2 = searchParams.get('ad');
    const skipp = searchParams.get('s');
    const ad2played = useRef(false);
    
    const skipLineText=useRef(null)



    const isAd2Exists = useRef(adindex2 !== undefined && !isNaN(Number(adindex2)));

    const imageRef=useRef()
 
    const history = useNavigate();

   for(let key in videolist){

    emergency_ids.push(videolist[key].id.videoId)
   }


    const videoRef=useRef(null)
     
    
   var isplay=true


   const handlePlayPause = ()=>{
    setPlay(!isplayed)
    
    const customVideo=document.getElementById("ad");

    isplayed ? customVideo.play():customVideo.pause()
    
    
}

 const handleCountdown=(newValue)=>{
  setCountdown(newValue)
 }


 function showSkippBnt(){
  var countdown=6
  
  var btnSkipp=document.getElementById("btnskipp");
  var videoTag=document.getElementById("ad")
  btnSkipp.style.display="flex"
  btnSkipp.style.visibility="visible"

  setTimeout(()=>{btnSkipp.style.opacity="0.9"},1000)
  countwdownInterval=setInterval(function(){
    countdown=countdown-1
    handleCountdown(countdown)
  
    if(videoTag!=null){
      if(videoTag.paused==true)
        clearInterval(countwdownInterval);
    }
 
  if(countdown==0){
  clearInterval(countwdownInterval);
  btnSkipp.classList.add("BtnSkippEnd")
  SkipBtnimg.current.style.display="none"
  countDownDiv.current.style.display="none"
  //btnSkipp.innerText="Werbung überspringen"
  skipLineText.current.style.display="flex"
  btnSkipp.style.pointerEvents="auto"
  }
  videoTag.onpause=function(){
  clearInterval(countwdownInterval);
  }
  videoTag.onplay=function(){
  countwdownInterval=setInterval(function(){countdown=countdown-1;
    handleCountdown(countdown)

  if(countdown==0 || countdown<0){
  clearInterval(countwdownInterval);
  btnSkipp.classList.add("BtnSkippEnd")
   SkipBtnimg.current.style.display="none"
  countDownDiv.current.style.display="none"
  //btnSkipp.innerText="Werbung überspringen"
  skipLineText.current.style.display="flex"
  btnSkipp.style.pointerEvents="auto"
  }
  },1000)
  }
  },1000)
  }


 function showSkippBnt2(){
  var countdown=5
  handleCountdown(countdown)
  var btnSkipp=document.getElementById("btnskipp");
  
  btnSkipp.style.display="flex"
  btnSkipp.style.visibility="visible"

  setTimeout(()=>{btnSkipp.style.opacity="0.9"},1000)
  countwdownInterval2=setInterval(function(){
    countdown=countdown-1
    handleCountdown(countdown)
   
  if(ad2.current.paused==true)
  clearInterval(countwdownInterval2);
  if(countdown==0){
  clearInterval(countwdownInterval2);
  btnSkipp.classList.add("BtnSkippEnd")
  SkipBtnimg.current.style.display="none"
  countDownDiv.current.style.display="none"
  //btnSkipp.innerText="Werbung überspringen"
  skipLineText.current.style.display="flex"
  btnSkipp.style.pointerEvents="auto"
  }
  ad2.current.onpause=function(){
  clearInterval(countwdownInterval2);
  }
  ad2.current.onplay=function(){
  countwdownInterval2=setInterval(function(){countdown=countdown-1;
    handleCountdown(countdown)

  if(countdown==0 || countdown<0){
  clearInterval(countwdownInterval2);
  btnSkipp.classList.add("BtnSkippEnd")
   SkipBtnimg.current.style.display="none"
  countDownDiv.current.style.display="none"
  //btnSkipp.innerText="Werbung überspringen"
  skipLineText.current.style.display="flex"
  btnSkipp.style.pointerEvents="auto"
  }
  },1000)
  }
  },1000)
  }



  


  const handleChangeVolumemore = (event, newValue)=>{
    const customVideo=document.getElementById("ad");
   
    customVideo.muted=false;
     setValue(newValue);


   customVideo.volume=newValue
   

}


const handleUnmute = ()=>{
  const customVideo=document.getElementById("ad");

  customVideo.muted=false;
  
 customVideo.volume=0.2
 setValue(0.2)


}

var player=null




const PlayPause = ()=>{
  var advr = document.getElementById("ad")
 
 
 
 if(AdEnded==true && playerX.getPlayerState()==1){
  playerX.pauseVideo()
 }
 else if(AdEnded==true && playerX.getPlayerState()==2){
  playerX.playVideo()
 }

 if(advr!=null){
 if(advr.paused==true && AdEnded==false){
  advr.play()
  setPlay(!isplayed)
  
 }

 else if(advr.paused==false && AdEnded==false){
  advr.pause()
  setPlay(!isplayed)
 }
}

if(isAd2Exists && ad2played.current){
  if(ad2.current.paused==true && AdEnded==false){
    ad2.current.play()
    
    setPlay(!isplayed)
    
   }
  
   else if(ad2.current.paused==false && AdEnded==false){
    ad2.current.pause()
    setPlay(!isplayed)
   }
}

}


 
   
    var youTube=document.getElementById("YouTubeVideo")

    function initYouTubeAPI() {
      if (window['YT'] && window['YT'].Player) {
        createPlayer();
      } else {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
        window['onYouTubeIframeAPIReady'] = function() {
          createPlayer(); 
        };
      }
    }
    

    
function createPlayer() {

  var YT=window['YT']

  player = new window['YT'].Player('player', {
    videoId: `${window.localStorage.getItem(1)}`,
    playerVars:{rel:0, autoplay: 1, modestbranding:1, fs:0, playsinline:1},
    events: {
      'onStateChange': onPlayerStateChange,
       'onReady':onPlayerReady,
     
    }

    
  });

  playerX=player


    function onPlayerReady(event) {
   duration=playerX.getDuration()
   playerX.pauseVideo()
    var index=0  
    var main_video_player=document.getElementById("videoplayer")
    var forwardWindow=document.getElementById("forwardWindow")
    document.getElementById("videolist").style.display="block"
    var timerInterval
    var timer=3

  


    
    if(event.target.getDuration()==0)
    {
      if(ids_count<emergency_ids.length-1){
        ids_count++
        sessionStorage.setItem('ids_count', ids_count)
        
        const myArrayIDs = sessionStorage.getItem('ids');
        const myArray = JSON.parse(myArrayIDs);

        myArray.forEach((item,index)=>{
          noIds.push(item)
        })

        noIds.push(localStorage.getItem(1))
        const myArraynoIds = JSON.stringify(noIds);

        sessionStorage.setItem('ids',myArraynoIds)

       
      }
      else{
        ids_count=1
        sessionStorage.setItem('ids_count', ids_count)

        const myArrayIDs = sessionStorage.getItem('ids');
        const myArray = JSON.parse(myArrayIDs);

        myArray.forEach((item,index)=>{
          noIds.push(item)
        })

        noIds.push(localStorage.getItem(1))
        const myArraynoIds = JSON.stringify(noIds);

        sessionStorage.setItem('ids',myArraynoIds)
       
   
      }
      
      closeAd()
      
      forwardWindow.style.display="block"
      document.getElementById("videolist").style.display="none"
      main_video_player.style.pointerEvents='none'
    
      main_video_player.style.zIndex="1"
      main_video_player.style.position="absolute"
      document.getElementById("player").style.filter="blur(2px)"
      document.getElementById("info").style.filter="blur(2px)"
      document.getElementById("infochannel").style.filter="blur(2px)"
      timerInterval=setInterval(()=>{
        timer--
     
      
      },1000)
     
      
       setTimeout(() => {
        clearInterval(timerInterval)
       
        if(document.getElementById("btnskipp")!=null){
        document.getElementById("btnskipp").style.display="none"
        }
        
      
      }, 3000);
      


      
    
    }

    

    }

      function onPlayerStateChange(event) {
    
        if (event.data == YT.PlayerState.ENDED) {
         VideoEnd=event.data
        }
      }


  }
  
  
  async function initiate(){
    await initYouTubeAPI();
    //like=Math.floor(Math.random()*1000);
  }
   
   const setAd=()=>{

initiate()

    

var advr = document.getElementById("ad")
var adpan = document.getElementById("adpanel")

document.getElementById("videoplayer").addEventListener('mouseover',function(){
  
  adpan.style.opacity=1
})

document.getElementById("videoplayer").addEventListener('mouseleave',function(){
  if(advr.paused===false){
    adpan.style.opacity=0
  }
  else if(advr.paused===true){
    adpan.style.opacity=1
  }
  
})

var videoTag=document.getElementById("ad")



  
   
    var circload=document.getElementById("circload")

    var footerBanner=document.getElementById("footerBanner")
    var closeIcon=document.getElementById("closeIcon")
    
 
    let intervalState
   
   
   

   
    if(type=="pre"){

      var Videoplayer=document.getElementById("player")
      videoTag.style.display="block" 
      Videoplayer.style.visibility="hidden"
      

      if(window.innerWidth<=480 ){
        Videoplayer.style.opacity="0"
        Videoplayer.style.visibility="hidden"
      }
     
    
     
    
     var dialog=document.getElementById("iosDialog")

  
   
     //if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
     //if (/Android/.test(userAgent) && !window.MSStream) {
    
     
        //ad1.current.pause()
        //videoTag.pause()
      

       //dialog.style.display="block"
       //document.body.style.overflow="hidden"
     

     
   //} 
   //else{
    

      videoTag.load()
      videoTag.play()
      

      time=0;
      videoDuration=0
    
    
     
     
      ad1.current.addEventListener('timeupdate', handleTimeUpdate)

  
 
    //}

  



    videoTag.addEventListener('waiting',function(){
      circload.style.display="block"
    })


    videoTag.addEventListener('playing',function(){
      circload.style.display="none"
    })

    videoTag.addEventListener('ended', function(){
     
      
      videoTag.style.display="none"
  
    })
     
    if(skipp==1){
      intervalState=setInterval(function(){if(videoTag.readyState>2){
        clearInterval(intervalState)
        
     
        setTimeout(()=>{showSkippBnt()},1000)
      }
    },1000)
  }

  else{

  }
    
    
     
      window.onload=function(){
        var mainplayer=document.getElementById("player")
        mainplayer.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')

        
        
      }

    

    }


  
  }


    

    useEffect(()=>{

      

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });

      window.matchMedia("(orientation: landscape)").addEventListener("change",e=>{
        const land=e.matches
      
        if(land){
          document.getElementById("videoplayer").style.marginTop=0
          document.getElementById("ad").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("player").style.height=parseInt(document.documentElement.clientHeight)+"px"
          document.getElementById("shield").style.height=parseInt(document.documentElement.clientHeight-57)+"px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','landscape')
          document.body.style.overflow="hidden"
         
        }
        else{
          document.getElementById("ad").style.height="270px"
          document.getElementById("player").style.height="270px"
          document.getElementById("shield").style.height="212px"
          document.getElementById("videoplayer").setAttribute('data-click-tracking','portrait')
          document.body.style.overflow="auto"
        }
      })
    
      
      //document.getElementById("menu").style.display="none"
     // document.getElementById("buttonPanel").style.display="none"
     {setAd()}
     
     if(window.innerWidth<=480){
      document.getElementById('nav').style.display="none"
     }
     else{
      document.getElementById('nav').style.display="block"
     }
    


    
     // window.addEventListener('focus', tabFocusAd)
     // window.addEventListener('blur', tabNoFocusAd)

    
    


  },[])
 

  
  
      return(
          
       
          
          <div className="Videoplayer" id="videoplayer" data-click-tracking="portrait">

        <Dialog onClick={iosStartVideo}/>

         <div className='ForwardWindow' id="forwardWindow">
        <img className='ForwardWindowImg' src={Error}/>
        <h2 className='ForwardWindowText' style={{color:'white'}}>Das Video ist nicht verfügbar. <span style={{color:'red'}}> Klicken Sie auf den Button. </span> Sie werden zürück zur Videoauswahl weitergeleitet </h2>
      
       <Link style={{textDecoration:'none'}} to={`/youtcheck/pre/${adindex2}?ad=${adindex2}&s=${skipp}`}>{<div className='Back'>Zurück zur Auswahl</div>}</Link> 

       
         </div>
       

            <div className="Overlay" onClick={PlayPause} id="shield">
            
          <div className='Adshell' id="adshell">  
          <CircularProgress className='CircLoad' id="circload" color="inherit"/>
      

          <video ref={ad1} poster={Loadi} src={DataAds.ads[adindex].url} className="ad-proceed" id="ad" preload="true" playsInline> </video>

         {isAd2Exists.current ? ( <video ref={ad2} poster={Loadi} src={DataAds.ads2[adindex2].url} className="ad-proceed2" id="ad2" preload="true" playsInline> </video>):<></>}
    
      <img className='IosLoadImg' id="iosloadimg" src={Loadi}/>

          <div className='AdPanel' id="adpanel" >
 
          <div className='videoTrack' id='vidTrack'>
       <div className='Timeline'></div>

       </div>
       <Stack spacing={2} id="volumebtn"  direction="row"  alignItems="center">
     
       {isplayed ? <PauseIcon style={{fontSize:25, background:'transparent'}} onClick={handlePlayPause} id="playbtn" className="btnPlay"></PauseIcon>:<PlayArrowIcon style={{fontSize:30, background:'transparent'}} id="pausebtn" onClick={handlePlayPause} className="btnPlay"></PlayArrowIcon>}
      
      
      <SkipNextIcon style={{fontSize:25, background:'transparent'}}/>
    
      <VolumeUp id="volumeicon"/>
      
        <Slider sx={{'.MuiSlider-thumb':{width:10,height:10, opacity:1}, '.MuiSlider-root':{width:"12%", padding:'0'},'.MuiSlider-rail':{width:"100%"}, backgroundColor: '#FFFFFF'}} id="slider" color="secondary" key={`slider-${value}`} aria-label="Volume"step={0.1} defaultValue={value} min={0} max={1} onChange={handleChangeVolumemore}/>
        

        
        <div className='SomeIcons'>
          <SubtitlesIcon style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <SettingsIcon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          
          <PictureInPictureAltIcon style={{fontSize:20, marginBottom:2, marginRight:20, background:'transparent'}}/>
          <Crop32Icon  style={{fontSize:25, marginRight:20, background:'transparent'}}/>
          <FullscreenIcon  style={{fontSize:25, background:'transparent'}}/>
        </div>
        </Stack>
        
        

       </div> 


          </div> 


          </div>


          
          
          <div className="OverlayCase">
          <div className="Video" id="player"></div>
          <div className='Coverlogo'></div>
          </div>
          
          <div className="BtnSkipp" id="btnskipp" onClick={closeAdBtnSkipp} variant='contained'>
          <div ref={countDownDiv} className='Countdown'>{countdown}</div>
          <img ref={SkipBtnimg} className='CountdownImg' src={window.localStorage.getItem(7)}/>
          <div ref={skipLineText} id='skip_ad-textline'><div>Überspringen</div> <svg height="100%" viewBox="-6 -6 36 36" width="35px"><path d="M5,18l10-6L5,6V18L5,18z M19,6h-2v12h2V6z" fill="#fff"></path></svg></div>
          </div>
          
          <div className="VideoInfo" id="info">

          <p className="VideoTitle" id="title" >{window.localStorage.getItem(2)}</p>

          </div>

        
        

          <div className="InfoaboutChannel" id="infochannel">
          <div className='ChannelPanel'>
            <div className='ImgAndAbo'>

              <div className='Container-mobile'>
            
            <div className='Channel-container'><img className="Channel" src={window.localStorage.getItem(7)}></img></div>
           
            <div className="p1">
            <div className='text-container'>{window.localStorage.getItem(3)}</div>
            </div>
            
            </div>
 
            <div className='AboDiv'>

             <div className='AboBtn'>Abonnieren</div>
            
             </div>

             </div>

             <div className='ButtonsPanel'>
             <div className='Btn '><div id="btnlike"><ThumbUpAltOutlinedIcon   sx={{fontSize:18}}> </ThumbUpAltOutlinedIcon> <p style={{marginLeft: "10px"}}>{like}</p></div>
             <div><ThumbDownOffAltOutlinedIcon id="btndislike" style={{fontSize:18}}> </ThumbDownOffAltOutlinedIcon></div></div>
            <div className='Teilen'><ReplyIcon id="btnteilen" style={{transform:'scaleX(-1)',fontSize:18}}></ReplyIcon><p>Teilen</p></div>
           
            <div className='Download'><VerticalAlignBottomOutlinedIcon sx={{marginLeft:'16px',fontSize:18}}></VerticalAlignBottomOutlinedIcon><p>Herunterladen</p></div>
            <div className='Clip'><ContentCutOutlinedIcon sx={{marginLeft:'7px',fontSize:18}}></ContentCutOutlinedIcon><p>Clip</p></div>
            <div className='Save'><QueueOutlinedIcon sx={{marginLeft:'12px',fontSize:18}}></QueueOutlinedIcon><p>Speichern</p></div>
            <div className='Punkte'><p>...</p></div>

            </div>
            </div>
             
                <div className="p3">
                  <div id="Comments">Kommentare</div>
                <div className='Descr'>{window.localStorage.getItem(4)} <span>Mehr</span></div>
                <div className='Comm'><AccountCircleIcon  sx={{fontSize:30, color:'#4487df'}}/> <div id="addCommText">Kommentar hinzufügen</div> </div>
                </div>
              
               
                
            
           
            
            </div>
          


            </div>

      )



       
   function handleTimeUpdate(){

    const videoTime= document.querySelector('.Timeline')

    if(ad1.current!=null){
      videoDuration=Math.round(ad1.current.duration)
      time=Math.round(ad1.current.currentTime)
   
    
  
 
    videoTime.style.width=(time*100)/videoDuration + "%"
  
    if(time>=videoDuration){
     if(isAd2Exists.current && !ad2played.current){
   
       nextAd()
      
     }
     else{
      console.log(isAd2Exists.current)
    
       closeAd()
     }
    }
  }
   }
      

      function nextAd(){
        
        const videoTime= document.querySelector('.Timeline')
        videoTime.style.width=0 + "%"
        ad1.current.removeEventListener('timeupdate', handleTimeUpdate)
        console.log(ad1.current)
        ad1.current.style.display="none"
        ad1.current.pause()
        ad1.current.currentTime=0
        ad1.current.remove()
        ad2.current.play()
        ad2played.current=true
        
        if(skipp==1)
        showSkippBnt2()
       
        ad2.current.addEventListener('timeupdate', function(){
       
          videoDuration=Math.round(ad2.current.duration)
          time=Math.round(ad2.current.currentTime)
        
       
          videoTime.style.width=(time*100)/videoDuration + "%"
          if(time>=videoDuration){
       
            closeAd()
          }
        })
      }
    
     

      function tabFocusAd(){
        var focusad=document.getElementById("ad")
        focusad.play()
      }
    
      function tabNoFocusAd(){
        var focusad=document.getElementById("ad")
        focusad.pause()
      }
    
     
      function iosStartVideo(){
    
       
        var customVideo=document.getElementById("ad");
        document.getElementById("iosloadimg").style.display="block"
        let loadInterval
        document.getElementById("iosDialog").style.display="none"
        //document.getElementById("ad").play()
        ad1.current.play()
    
        playerX.playVideo()
        playerX.pauseVideo()
        
        loadInterval=setInterval(()=>{
          if(document.getElementById("ad").readyState==4){
            document.getElementById("iosloadimg").style.display="none"
            clearInterval(loadInterval)
          }
        },500)
    
        
    
        time=0;
        videoDuration=0
      // const customVideo=document.getElementById("ad");
       const videoTime= document.querySelector('.Timeline')
       //ad1.current.addEventListener('timeupdate', handleTimeUpdate)
        
       customVideo.addEventListener('timeupdate', function(){
       
         videoDuration=Math.round(customVideo.duration)
         time=Math.round(customVideo.currentTime)
       
      
         videoTime.style.width=(time*100)/videoDuration + "%"
         if(time>=videoDuration){
           closeAd()
         }
       })
    
    
        
        
        document.body.style.overflow="auto"
      
     
      }
     
     
       function closeFooter(){
        var footerdiv=document.getElementById("FooterDivId")
        footerdiv.style.display="none"
      }
      
    
    
     
    
    
      function closeAd(){
        
        if(isAd2Exists.current){
          ad2.current.pause()
          ad2.current.remove()
        }

        let btnApperar=7
        timeafterskipbtn=time-btnApperar
        timeafterskipvid=time
        AdEnded=true
        var mainplayer=document.getElementById("player")
        var videoTrack=document.getElementById("vidTrack")
        var playbtn =document.getElementById("playbtn")
        var pausebtn=document.getElementById("pausebtn")
        var btnSkipp=document.getElementById("btnskipp");
        var volumebtn=document.getElementById("volumebtn")
        var adpanel=document.getElementById("adpanel")
        var adshell=document.getElementById("adshell")
    
        window.removeEventListener('focus', tabFocusAd)
        window.removeEventListener('blur', tabNoFocusAd)
    
        videoTrack.style.display="none"
       
        volumebtn.style.display="none"
        adpanel.style.display="none"
        adshell.style.display="none"
    
        mainplayer.style.opacity="1"
        mainplayer.style.visibility="visible"
        btnSkipp.style.display="none"
        var videoTag=document.getElementById("ad")
        ad1.current.remove()
        
        clearInterval(current_time_interval)
     
        try{
          mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
          playerX.playVideo()
        
    
          if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
            mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
            playerX.playVideo()
          }
        }
        catch{
          
         
       
        }
    
       
        
      }
    
    
    
      function closeAdBtnSkipp(){
        const videoTime= document.querySelector('.Timeline')
         videoTime.style.width=0+"%"
        var btnSkipp=document.getElementById("btnskipp");
        console.log(ad2played.current)
        if(!ad2played.current){
         
          let btnApperar=7
          timeafterskipbtn=time-btnApperar
        timeafterskipvid=time
          console.log("test")
          btnSkipp.classList.remove("BtnSkippEnd")
           SkipBtnimg.current.style.display="block"
          countDownDiv.current.style.display="flex"
          skipLineText.current.style.display="none"
           btnSkipp.style.visibility="hidden"
           btnSkipp.style.pointerEvents="none"
          nextAd()
        }
        else{
          if(isAd2Exists.current){
            ad2.current.style.display="none"
          }
        let btnApperar=7
        timeafterskipbtn=time-btnApperar
        timeafterskipvid=time
        AdEnded=true
        var mainplayer=document.getElementById("player")
        var videoTrack=document.getElementById("vidTrack")
        var playbtn =document.getElementById("playbtn")
        var pausebtn=document.getElementById("pausebtn")
       
        var volumebtn=document.getElementById("volumebtn")
        var adpanel=document.getElementById("adpanel")
        var adshell=document.getElementById("adshell")
    
        window.removeEventListener('focus', tabFocusAd)
        window.removeEventListener('blur', tabNoFocusAd)
    
        videoTrack.style.display="none"
       
        volumebtn.style.display="none"
        adpanel.style.display="none"
        adshell.style.display="none"
    
        mainplayer.style.opacity="1"
        mainplayer.style.visibility="visible"
        btnSkipp.style.display="none"
        var videoTag=document.getElementById("ad")
        if(videoTag!=null){
        videoTag.style.display="none"
        videoTag.pause()
      }
      ad2.current.pause()
      ad2.current.remove()
        clearInterval(current_time_interval)
     
        try{
          mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
          playerX.playVideo()
        
    
          if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
            mainplayer.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
            playerX.playVideo()
          }
        }
        catch{
          
         
       
        }
    
      }
        
      }



  }



 

 


  export default VideoPlayer